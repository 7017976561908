.container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: var(--secondary);
  transition: transform 0.3s ease-in-out;
}

.container.navbarShrinked nav {
  padding: 0 0;
}

.container.navbarHidden {
  transform: translateY(-100%);
}

.container:focus-within {
  transform: translateY(0);
}

@media only screen and (max-width: 1180px) {
  .container.navbarShrinked nav {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 1920px) {
  .container.navbarShrinked nav {
    padding: 0;
  }
}
