.container {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .sum {
    .number {
      display: inline-block;
      background: var(--primary);
      color: var(--secondary);
      padding: 0.2rem 1rem;
      border-radius: 1rem;
    }
  }

  .context {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .sum {
      font-size: 0.9rem;
    }

    .context {
      font-size: 0.7rem;
    }
  }
}
