.graph {
  display: flex;
  height: 30px;
  background: var(--primary);
}

.graph div {
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
  align-items: flex-end;
  border-right: 1px solid var(--secondary);
  height: 100%;
  text-align: right;
  white-space: nowrap;
  position: relative;
  transition: width 1000ms;
}

.graph div span {
  opacity: 0;
  margin-right: 6px;
  line-height: 14px;
  transition: opacity 180ms;
  user-select: none;
}

.graph div:hover span {
  opacity: 1;
}

.graph div:last-child {
  border-right: none;
}

@media only screen and (max-width: 1180px) {
  .graph {
    display: none;
  }
}
