.table {
  width: calc(100% - 1px);
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  padding-left: 1.5rem;
  border-left: 1px solid var(--primary);
}

.table tbody td:last-child {
  text-align: right;
}
