.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    .actions {
      justify-content: space-between;

      button {
        margin-left: 0;
      }
    }
  }

  .textWrapper h5 {
    margin: 3rem 0;
  }
}
