.wrapper {
  border-radius: 5px;
  width: 100%;
  margin-bottom: 30px;
  max-width: 740px;
}

.wrapper h3 {
  margin-top: 50px;
  margin-bottom: 55px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-bottom: 4rem;
}

.grid {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: 1fr;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  max-width: 740px;
}

.input {
  font-size: 25px;
}

.input label {
  margin-right: 20px;
}

.input input {
  font-size: 25px;
  border: none;
  border-bottom: 1px solid var(--primary);
  background: var(--secondary);
  position: relative;
  width: 170px;
  text-align: right;
  padding-right: 34px;
  height: 33px;
  padding-bottom: 0px;
  padding-top: 0;
  border-radius: 0;
  font-family: "ESKlarheitGrotesk";
  line-height: 33px;
}

.inputWrapper {
  position: relative;
  display: inline-block;
}

.inputWrapper::after {
  content: "kr";
  position: absolute;
  right: 6px;
  bottom: 0px;
  font-size: 25px;
  line-height: 32px;
  touch-action: none;
  height: 31px;
  bottom: 1px;
}

.select {
  padding-top: 20px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 30px;
  padding-bottom: 2rem;
}

.select button {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid var(--primary);
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

.select button:hover {
  color: var(--secondary);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  outline: none;
}

.select button:focus {
  outline: 2px solid var(--primary);
}

.select button:active {
  color: var(--secondary);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  outline: none;
}

.select .selected {
  background: var(--primary);
  color: var(--secondary);
}

.spinnerWrapper {
  width: 100%;
  margin-top: 20px;
  height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.output {
  padding-top: 10px;
}

.output .paragraphWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.output .paragraphWrapper .paragraphNumber {
  font-family: "ESKlarheitKurrent";
  font-size: 128px;
  line-height: 110px;
  margin-right: 20px;
}

.output .explanatory {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
}

.output .paragraphWrapper .innerParagraphNumber {
  display: none;
}

.output p {
  text-align: left;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0;
}

.output .paragraphWrapper div > span {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  display: inline-block;
}

.output .paragraphWrapper div > span::after {
  content: "↓";
  display: inline-block;
  transition: all 100ms;
  transform: rotate(0deg);
}

.output .paragraphWrapper div > .captionopen::after {
  transform: rotate(180deg);
}

.output .context {
  padding-top: 30px;

  p {
    font-size: 1rem;
  }
}

.output .giveButtonWrapper {
  margin-top: 3rem;

  button {
    font-size: 0.8rem;
    padding: 0.75rem 4rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .output .giveButtonWrapper button {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1180px) {
  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }

  .select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 0;
  }

  .select button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .output {
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }

  .output .paragraphWrapper {
    margin-top: 0;
    justify-content: center;
  }

  .output .paragraphWrapper .paragraphNumber {
    display: none;
  }

  .output .paragraphWrapper .innerParagraphNumber {
    display: inline;
  }

  .output .paragraphWrapper .innerParagraphNumber::after {
    content: " ";
  }

  .output .paragraphWrapper p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .output .paragraphWrapper div > span {
    margin-top: 10px;
  }

  .output .context {
    padding-top: 10px;
  }
}
