.organizationWrapper .organization:first-child {
  border-top: none;
  padding-top: 10px;
}

.organizationWrapper h4 {
  margin-top: 0;
}

.organization {
  padding-top: 6rem;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  border-top: 1px solid var(--primary);
}

.organization .headerwrapper {
  h4,
  h5 {
    &::after {
      /* Arrow right */
      content: "→";
      font-family: "ESKlarheitGrotesk";
      margin-left: 0rem;
      opacity: 0;
      vertical-align: text-top;
      line-height: 100%;
      transition: all 0.15s;
    }

    &:hover {
      &::after {
        margin-left: 0.75rem;
        opacity: 1;
      }
    }
  }
}

.meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 50px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p:first-child {
    margin-top: 0;
  }
}

.intervention {
  display: flex;
  flex-direction: column;
}

.intervention h1 {
  margin: 0;
}

.interventionSubtitle {
  margin-bottom: 10em;
}

.buttonWrapper {
  display: flex;
  padding-top: 2rem;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 0.25rem; /* Account for line height on the left column */
}

@media only screen and (max-width: 1180px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .organization {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding-top: 4rem;
  }

  .organization .meta {
    margin-bottom: 4rem;
  }

  .organization .headerwrapper {
    h4,
    h5 {
      &::after {
        display: none;
      }
    }
  }

  .intervention {
    margin-top: 20px;
  }

  .interventionSubtitle {
    margin-bottom: 2em;
  }

  .organization:last-child {
    margin-bottom: 0;
  }
}
