.widgetPane {
  width: 40vw;
  background: var(--primary);
  border-left: 1px solid var(--secondary);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(40vw) translateX(250px);
  transition: transform 500ms cubic-bezier(0.25, 0.72, 0.24, 1);
  z-index: 10000;
  color: var(--secondary);
  --secondary-hover: #bbb;
}

.widgetPaneOpen {
  transform: translateX(0);
}

.widgetCloseBtn {
  cursor: pointer;
}

.widgetCloseBtn:hover {
  opacity: 0.8;
}

.widgetPaneHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.widgetPaneContent {
  --primary: #fafafa;
  --secondary: black;
  --primary-hover: #aaa;
  height: 100%;
}

.widgetPaneContentDark {
  --primary: black;
  --secondary: #fafafa;
  --primary-hover: #aaa;
  height: 100%;
}

@media only screen and (max-width: 1180px) {
  .widgetPane {
    min-width: auto;
    width: 100vw;
    max-width: 100vw;
    transform: translateX(0);
    transform: translateY(100vh) translateY(200px);
    border-width: 0;
  }

  .widgetPaneOpen {
    transform: translateY(0px);
  }
}
