.container {
  display: flex;
  flex-direction: column;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 100px;
  justify-content: space-between;
}

.gridContainer section h5 {
  margin-bottom: 40px;
}

/* Mobile view */
@media only screen and (max-width: 1180px) {
  .gridContainer {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    row-gap: 40px;
  }
}
