.gridContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 100px;
  justify-content: space-between;
}

.cellTitle {
  margin-top: 40px;
}

.infoGridCell {
  border-top: 1px solid var(--primary);
}

.editGridCell {
  border-top: 1px solid var(--primary);
}

.infoSection {
  font-size: 18px;
  margin-top: 40px;
  white-space: pre;
  line-height: 16px;
}

.header {
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Mobile view */
@media only screen and (max-width: 1180px) {
  .gridContainer {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    row-gap: 40px;
  }
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .header {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1180px) {
  .container {
    padding-top: 40px;
  }

  .header {
    display: none;
  }
}
