.wrapper {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.checkboxwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8em;
  height: 1.8em;
  min-width: 1.8em;
  min-height: 1.8em;
  border: 1px solid var(--primary);
}

.checkmark {
  font-size: 1em;
  color: var(--primary);
  display: none;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkboxwrapper .checkmark {
    display: block;
  }
}

.checkboxlabel {
  display: inline-block;
  font-size: 1em;
  margin: 0;
  margin-left: 0.8em;

  a:link {
    text-decoration: underline;
  }
}
