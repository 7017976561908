.container {
  background-color: var(--primary);
  color: var(--secondary);
  text-align: center;
  padding: 6px 0;
  font-size: 0.75rem;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 2100px;
  margin: 0 auto;
  align-items: center;
  padding: 0 5vw;
  width: 100%;

  .title {
    margin-right: 2rem;
  }

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    flex: 1;

    button {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .content {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1180px) {
  .content {
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 5vw;
    gap: 0.5rem;

    .title {
      display: none;
    }

    .description {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.4rem;

      p {
        margin: 0;
      }
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}
