.container {
  min-width: 500px;
}

.container .typeSelector {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.container .inputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.container .inputContainer p {
  margin-top: 0.5rem;
}

.container .selectorContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.container .inputContainer:last-child {
  margin-bottom: 1rem;
}

.container .ssnValidation {
  margin-top: 0.3rem;
  font-size: 0.8rem;
}

.container .error {
  margin-top: 1rem;
  max-width: 500px;
}

.container .error::before {
  // content with the utf8 warning mark
  content: "\26A0";
  display: inline-block;
  margin-right: 0.2rem;
  vertical-align: middle;
}

@media screen and (max-width: 1180px) {
  .container {
    min-width: 100%;

    h5 {
      margin: 3rem 0;
    }
  }
}
