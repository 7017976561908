.wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  container-type: inline-size;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row;
  column-gap: 1.5em;
  row-gap: 1em;
  width: 100%;
}

.share-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
  overflow: hidden;

  & > div {
    position: relative;

    span {
      position: absolute;
      right: 0;
      top: 0;
      color: var(--primary);
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 0.65em;
    }
  }

  span {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    font-family: "ESKlarheitGrotesk";
    padding: 0.5em 1.2em;
    padding-left: 0;
    padding-right: 1.8em;
    border: none;
    color: var(--primary);
    background: var(--secondary);
    width: 4em;
    text-align: right;
    border-bottom: 1px solid var(--primary);
    font-size: 1em;
  }
}

.warning-box {
  padding: 0.5em 1.2em;
  font-size: 0.8em;
  margin-top: 1.5em;
  color: var(--secondary);
  background: var(--primary);
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    border-left: 1px solid var(--secondary);
    display: flex;
    padding-left: 1em;
    height: 100%;
    align-items: center;
    margin-left: 1em;
    font-weight: 600;
  }
}

@container (max-width: 45em) {
  .grid {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .warning-box {
    width: 100%;
    justify-content: space-between;
  }

  .share-wrapper {
    font-size: 1rem;
  }

  .share-wrapper input {
    width: 6em;
  }
}
