.fullpagewrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  color: var(--primary);
  position: fixed;
  top: 0;
  left: 0;
}

.fullpagewrapper .buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.fullpagewrapper .buttons button {
  margin-left: 0;
}
