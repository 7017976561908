.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 80px;
  width: 100%;
  margin: 50px 0;
}

.groupanswers > div {
  border-bottom: none;
}

.groupanswers > div:last-child {
  border-bottom: 1px solid var(--primary);
}

@media only screen and (max-width: 1180px) {
  .grid {
    grid-template-columns: auto;
    margin: 30px 0;
  }
}
