.container {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--primary);
  border-radius: 0.5em;
  flex-grow: 1;

  position: relative;

  .button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.5em 1.2em;
    width: 100%;

    .left {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;
    }

    .right {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      flex-shrink: 1;
      overflow: hidden;

      .ssn {
        font-size: 0.75em;
        margin-right: 0.5em;
        margin-left: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .menu {
    position: absolute;
    top: calc(100% + 0.5em);
    left: 0;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    color: var(--secondary);
    background: var(--primary);
    border-radius: 0.5em;
    z-index: 100;

    .menuItem {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.8em 1.2em;
      cursor: pointer;
      font-size: 0.8em;

      .inner {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid transparent;
        padding: 0 0.05em;

        .name {
          text-align: left;
        }

        .ssn {
          text-align: right;
          vertical-align: bottom;
        }
      }

      &:last-child {
        border-top: 1px solid var(--secondary);
      }

      &:hover .inner {
        border-bottom: 1px solid var(--secondary);
      }
    }
  }
}
