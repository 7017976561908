.wrapper {
  width: 100%;
  max-width: 760px;
  margin: 20px 0;

  &.fullwidth {
    max-width: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.grayscale {
  filter: grayscale(1);
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .wrapper {
    max-width: 640px;

    &.fullwidth {
      max-width: none;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    max-width: auto;
  }
}
