.contributors {
  width: 100%;
}

.contributors__title {
  align-self: flex-start;
  word-wrap: break-word;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.contributors__list {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: min-content;
  row-gap: 50px;
  column-gap: 40px;
  max-width: 100%;
  margin-bottom: 0;
}

@media (max-width: 1180px) {
  .contributors__list {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    column-gap: 20px;
  }

  .contributors {
    margin-bottom: 20px;
  }

  .contributors__title {
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 0;
  }
}
