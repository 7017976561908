.pageContent {
  padding: 0 5vw;
}

@media only screen and (min-width: 1920px) {
  .pageContent {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
