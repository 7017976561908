.container {
  padding: 0 0;
  margin: 0 auto;
  padding-top: 106px;
}

.modalOpen {
  overflow: hidden;
}

.main {
  min-height: 60vh;
  color: var(--primary);
  background: var(--secondary);
}

.dark {
  --primary: #fafafa;
  --secondary: black;
  --primary-filter: invert(1);
  --primary-hover: #ccc;
  background: var(--secondary);
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .container {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 1180px) {
  .container {
    padding-top: 72px;
  }
}

@media print {
  .container {
    min-height: 100vh;
  }
}
