.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 30px 0;
}

.impactEstimate {
  width: 100%;
}

.actions ul {
  list-style: none;
  margin: 0;
  margin-top: 20px;
  padding: 0;
}

.actions ul li span {
  vertical-align: middle;
}

.actions ul li svg {
  vertical-align: middle;
  margin-right: 4px;
}

.actions ul li a {
  text-decoration: none;
}

.actions ul li span {
  text-decoration: underline;
}

.caption {
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
  display: block;
}

.caption::after {
  content: "↓";
  display: inline-block;
  transition: transform 200ms;
  transform: rotate(0deg);
}

.captionopen::after {
  transform: rotate(180deg);
}

.impactExplanationContainer {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 0;
  padding-left: 2rem;
  flex-direction: column;
  border-left: 1px solid var(--primary);
  margin-top: 1rem;
}

.impactExplanationContainer p {
  margin: 0;
}
