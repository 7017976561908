.container {
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 5rem;
  margin: 3rem 0;

  .content {
    h4 {
      margin-bottom: 0.5rem;
      margin-top: 2rem;
    }

    .contact {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      font-size: 0.8rem;
      line-height: 140%;
      margin-bottom: 2rem;
    }

    .text {
      a {
        border-bottom: 1px solid var(--primary);
      }

      a:focus {
        outline: 1px solid var(--primary);
      }

      a:active {
        outline: none;
      }
    }
  }

  .image {
    .mobileTitle {
      display: none;
    }

    .innerImage {
      position: relative;
      aspect-ratio: 7 / 8;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .container {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;

    .image {
      display: grid;
      grid-template-columns: 4fr 6fr;
      gap: 2rem;
      align-items: center;

      .mobileTitle {
        display: flex;
        flex-direction: column;

        h4 {
          margin: 0;
        }

        .contact {
          display: flex;
          flex-direction: column;
          font-size: 0.8rem;
          gap: 0.5rem;
          margin-top: 0.75rem;

          a {
            border-bottom: 1px solid var(--primary);
            display: inline-block;
            width: fit-content;
            line-height: 140%;
          }
        }
      }
    }

    .content {
      h4 {
        display: none;
      }

      .contact {
        display: none;
      }
    }
  }
}
