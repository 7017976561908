.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
}

.values {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  font-size: 1rem;

  & > div {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &.valuesDatePickerContainer {
      width: 100%;

      & > div {
        width: 100%;
      }
    }

    &.valuesAmountContainer {
      input {
        width: 100%;
      }

      /* Currency label positioned to the right in the field */
      span {
        position: absolute;
        right: 1.2rem;
      }
    }

    &.valuesTaxUnitSelectorContainer {
      width: 100%;

      & > div {
        width: 100%;
      }
    }
  }

  input {
    background: var(--secondary);
    color: var(--primary);
    font-family: "ESKlarheitGrotesk";
    box-sizing: border-box;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--primary);
    padding: 0.5rem 1.2rem;
    width: 100%;
  }
}

.causeAreas {
  & > div {
    margin-top: 1.5rem;
  }

  & div:first-child {
    margin-top: 0;
  }

  .distributionCauseAreaInputHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  .distributionCauseAreaInputPercentageShare {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      background: var(--secondary);
      color: var(--primary);
      font-family: "ESKlarheitGrotesk";
      box-sizing: border-box;
      font-size: 1rem;
      border-radius: 0.5rem;
      border: 1px solid var(--primary);
      padding: 0.5rem 1.2rem;
      width: 100%;
    }

    span {
      position: absolute;
      right: 1.2rem;
    }
  }

  .distributionCauseAreaInputContainer {
    padding: 2rem 0;
  }
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 2rem;

    .values {
      flex-direction: column;
      row-gap: 1.5em;
      font-size: 1rem;

      input {
        width: 100%;
      }

      & > div {
        &.valuesDatePickerContainer {
          width: 100%;

          & > div {
            width: 100%;
          }
        }

        &.valuesAmountContainer {
          input {
            width: 100%;
          }
        }

        &.valuesTaxUnitSelectorContainer {
          width: 100%;

          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
