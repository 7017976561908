.inverted {
  --primary: #fafafa;
  --secondary: #000;
  --primary-filter: invert(1);
  background: #000;
  color: #fafafa;
  position: relative;
}

.inverted nav.navbarExpanded {
  --primary-filter: invert(0);
}

.inverted::before {
  content: "";
  position: absolute;
  width: 100%;
  top: -200px;
  height: 200px;
  background: #000;
}

.articles {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 50px;
  row-gap: 40px;
}

.articles a:first-child {
  grid-column: span 2;
  grid-row: span 2;
}

@media only screen and (max-width: 1180px) {
  .articles {
    margin-top: 20px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: 30px;
  }

  .articles a:first-child {
    grid-column: auto;
    grid-row: auto;
  }
}

@media only screen and (min-width: 1920px) {
}
