.teasers {
  width: 100%;
}

.teasers > div:nth-child(2n + 1) > div:nth-child(1) {
  grid-column: 2;
  grid-row: 1;
}

.teasers > div:nth-child(2n + 1) > div:nth-child(2) {
  grid-column: 1;
  grid-row: 1;
}

/* Slightly offsets text to accoutn for inverted middle section */
.teasers > div:nth-child(1) > div:nth-child(2) {
  transform: translateY(-2rem);
}

.teasers > div:nth-child(3) > div:nth-child(2) {
  transform: translateY(2rem);
}

@media only screen and (max-width: 1180px) {
  .teasers div:nth-child(2n + 1) > div:nth-child(1) {
    grid-column: auto;
    grid-row: auto;
  }

  .teasers div:nth-child(2n + 1) > div:nth-child(2) {
    grid-column: auto;
    grid-row: auto;
  }

  .teasers {
    padding-top: 2rem;
  }

  /* Negates offsets for text on mobile */
  .teasers > div:nth-child(1) > div:nth-child(2) {
    transform: none;
  }

  .teasers > div:nth-child(3) > div:nth-child(2) {
    transform: none;
  }
}
