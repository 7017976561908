.links {
  list-style: none;
  padding-left: 0;
}

.links li {
  padding: 4px 0px;
}

.linksWrapper {
  width: 100%;
  max-width: 760px;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .linksWrapper {
    width: 100%;
    max-width: 640px;
  }
}
