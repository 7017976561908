.accordion {
  width: 100vw;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;

  .header,
  .bottomBorder {
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid var(--primary);

    h2 {
      font-size: 1rem;
      margin: 0;
      line-height: normal;
    }

    span {
      display: inline-block;
      transition: all 200ms;
    }
  }

  .bottomBorder {
    border-bottom: 1px solid var(--primary);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .accordion {
    .header,
    .bottomBorder {
      max-width: 640px;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .accordion {
    width: 100%;
    margin-bottom: 20px;
  }
}
