.wrapper {
  width: 100vw;
  max-width: 1920px;
  margin: 20px 0;
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
