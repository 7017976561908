.summaryTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  margin-top: 5rem;
}

.summaryTable thead tr th {
  text-align: left;
  font-weight: normal;
  font-style: italic;
  padding-bottom: 0.5rem;
}

.summaryTable tbody tr td {
  padding-bottom: 0.5rem;
}

.summaryTable tbody tr td:last-child {
  text-align: right;
}

.summaryTable tbody tr:first-child td {
  border-top: 1px solid var(--primary);
  padding-top: 0.5rem;
}

.summaryTable tbody tr:last-child td {
  border-top: 1px solid var(--primary);
  padding-top: 0.5rem;
}

.nonDeductibleDonationsTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  margin-top: 3rem;
}

.nonDeductibleDonationsTable thead tr th {
  text-align: left;
  font-weight: normal;
  font-style: italic;
  padding-bottom: 0.5rem;
}

.nonDeductibleDonationsTable thead tr th .cellContent {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}

.nonDeductibleDonationsTable thead tr th svg {
  margin-left: 0.5rem;
  cursor: pointer;
}

.nonDeductibleDonationsTable tbody tr td {
  padding-bottom: 0.5rem;
}

.nonDeductibleDonationsTable tbody tr td:last-child {
  text-align: right;
}

.nonDeductibleDonationsTable tbody tr:first-child td {
  border-top: 1px solid var(--primary);
  padding-top: 0.5rem;
}

.nonDeductibleDonationsTable tbody tr:last-child td {
  border-bottom: 1px solid var(--primary);
  padding-top: 0.5rem;
}

.mobileOrgInfo {
  display: none;
}

@media only screen and (max-width: 1180px) {
  .summaryTable {
    margin-top: 3rem;
  }

  .nonDeductibleDonationsTable {
    margin-top: 3rem;
  }

  .nonDeductibleInfo {
    padding-top: 4rem;
    padding-bottom: 1rem;
  }

  .mobileOrgInfo {
    display: block;
    margin-top: 5rem;
  }
}
