.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  padding: 3rem 0;

  .description {
    padding-right: 6rem;
    display: flex;
    flex-direction: column;

    gap: 1.5rem;

    .button {
      a {
        padding: 0.5rem 2rem;
      }
    }
  }

  .people {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
  }
}

@media (max-width: 1180px) {
  .container {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    width: 100%;

    .description {
      padding-right: 0;
      gap: 0.5rem;
    }

    .people {
      grid-gap: 1rem;
    }
  }
}
