.contributor {
  display: flex;
  flex-direction: column;

  & a:hover {
    border: none;
  }
}

.contributor__image {
  width: 100%;
  position: relative;
  aspect-ratio: 24/31;
  filter: grayscale(100%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.contributor__title {
  margin-top: 1rem;
  margin-bottom: 0.1rem;
}

.contributor__subrole {
  margin-bottom: 6px;
}

.contributor__subrole,
.contributor__additional {
  margin-top: 4px;
  word-break: break-word;
  white-space: pre-wrap;
}

.contributor__email {
  padding-top: 8px;
  margin-top: auto;
  justify-self: flex-end;
  word-break: break-word;
}

@media (max-width: 1180px) {
  .contributor__title {
  }
  .contributor__subrole,
  .contributor__additional {
  }

  .contributor__email {
    /* Mobile smallsize */
    font-size: 12px;

    .contributor__email_name {
      display: none;
    }
  }
}
