/** -----News Letter Signup--------*/
.newsletter {
  width: 100%;
  max-width: 760px;
}

.newsletter fieldset {
  border: none;
  padding: 0;
  width: 100%;
  margin-left: 0;
}

.newsletter_label {
  font-size: 1rem;
}

.input__inlinebutton {
  margin-top: 14px;
  position: relative;
}
.input__inlinebutton input {
  font-size: 14px;
  border: none;
}

.input__inlinebutton input[type="email"] {
  padding: 14px 22px;
  box-sizing: border-box;
  border-radius: 50px;
  width: 100%;
  background-color: var(--secondary);
  border: solid 1px var(--primary);
}

.input__inlinebutton button {
  position: absolute;
  letter-spacing: 1px;
  height: 100%;
  padding: 0px 22px;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 14px;
}

.input__inlinebutton input[type="submit"]:hover {
  color: var(--primary-hover);
}

.newsletter input[type="email"] {
  background: var(--secondary);
  color: var(--primary);
  font-family: "ESKlarheitGrotesk", sans-serif;
}

.newsletter__input {
  background: var(--secondary);
  font-family: "ESKlarheitGrotesk", sans-serif;
  color: var(--primary);
  border: none;
  width: 100%;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .newsletter {
    max-width: 640px;
  }
}
