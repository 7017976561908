.donation-widget {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  will-change: height;

  &__back-button {
    position: absolute;
    left: 0;
    top: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 2rem;
    transition: all 0.3s ease;
  }

  &__spinner {
    filter: invert(1);

    $spinner-base-size: 1rem;
    width: $spinner-base-size;
    height: $spinner-base-size;

    &:after {
      width: $spinner-base-size * 0.8;
      height: $spinner-base-size * 0.8;

      margin: $spinner-base-size * 0.1;
      border-width: $spinner-base-size * 0.125;
    }
  }

  &__panes {
    position: relative;
    overflow: hidden;
  }

  &__pane {
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
    will-change: transform, opacity;

    // Forward direction animations
    &--entering-forward {
      transform: translateX(30px);
      opacity: 0;
    }

    &--exiting-forward {
      transform: translateX(-30px);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition-duration: 100ms;
    }

    // Backward direction animations
    &--entering-backward {
      transform: translateX(-30px);
      opacity: 0;
    }

    &--exiting-backward {
      transform: translateX(30px);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition-duration: 100ms;
    }

    &--active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__form {
    padding-top: 3.5rem;
  }

  &__input-group {
    margin-bottom: 1rem;
    position: relative;

    label {
      display: block;
      margin-bottom: 0.4rem;
      font-weight: 500;
    }

    input,
    textarea {
      width: 100%;
      padding: 0.6rem;
      border: 1px solid var(--primary);
      border-radius: 8px;
      font-family: "ESKlarheitGrotesk", sans-serif;
      font-size: 1rem;
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }

    &__suggested-sums {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;

      &__button {
        padding: 0.6rem 1rem;
        border: 1px solid var(--primary);
        border-radius: 0.5rem;
        background-color: white;
        cursor: pointer;
        font-weight: 500;
        font-size: 1rem;
        flex-grow: 1;

        &:hover {
          background-color: var(--primary);
          color: var(--secondary);
        }

        &--active {
          background-color: var(--primary);
          color: var(--secondary);
        }
      }
    }

    .donation-widget__input-group__suffix {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__message-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.4rem;

    label {
      margin-bottom: 0;
    }
  }

  &__checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    flex-direction: row;
    position: relative;

    input[type="checkbox"] {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
    }

    &__popup-trigger {
      margin-left: 0.5rem;
      cursor: pointer;
      color: var(--primary);
      border: 1px solid var(--primary);
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.8rem;
      display: inline-flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      &:hover + .donation-widget__checkbox-group__popup {
        display: block;
      }
    }

    &__popup {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      border: 1px solid var(--primary);
      border-radius: 8px;
      padding: 1rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      z-index: 100;
      display: none;
      transform: translateY(0.5rem);
    }
  }

  &__payment-options {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__payment-option {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    input[type="radio"] {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &__account-info {
    margin: 20px 0;

    p {
      margin: 8px 0;
    }
  }

  &__button {
    width: 100%;
    background-color: var(--primary);
    color: var(--secondary);
    border: none;
    border-radius: 999px;
    padding: 16px;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 2rem;
  }

  &__privacy-link {
    margin-top: 20px;
    text-align: left;
    font-size: 0.9rem;
    a {
      border-bottom: 1px solid var(--primary);
    }
  }

  &__bottom_shade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fafafa 100%);
  }
}

@media only screen and (max-width: 1180px) {
  .donation-widget {
    width: 100vw;
    transform: translateX(-5vw);
    position: relative;

    &__back-button {
      left: 5vw;
    }

    &__pane {
      padding: 0 5vw;
    }
  }
}
