.article {
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.article .article__meta {
  text-transform: uppercase;
  margin-top: 20px;
}

.article h2 {
  font-weight: normal;
  margin-top: 1rem;
  word-break: break-word;
  font-size: 1.2rem;
  line-height: 120%;
}

.article p {
  white-space: pre-wrap;
}

@media only screen and (max-width: 1180px) {
  .article h2 {
    font-size: 24px;
    margin-top: 6px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .article h2 {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1920px) {
  .wrapper {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
