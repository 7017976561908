.activity-indicator-wrapper {
  transition: all 200ms ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  position: fixed;
  top: 48px;
  left: 50%;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  background: var(--primary);
  border-radius: 50%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity-indicator-wrapper--active {
  transform: translateY(0);
  opacity: 1;
}

.activity-indicator-wrapper > div {
  transform: scale(0.3);
  transform-origin: center center;
}

.activity-indicator-wrapper > div:after {
  border-color: var(--secondary) transparent var(--secondary) transparent;
  margin: 2px;
}
