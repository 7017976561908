.fundraisercontainer {
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 4rem;
  width: 90vw;
  margin: 0 auto;
  margin-top: 5rem;
  max-width: 1714px;
}

.fundraisercontainer .fundraiserdescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 760px;
}

.fundraisercontainer .fundraiserdescription > h1 {
  margin-top: 0;
  font-size: 2rem;
  line-height: 150%;
}

.fundraisercontainer .fundraiserdescription > * {
  max-width: 100%;
  width: 100%;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .fundraisercontainer .fundraiserdescription {
    max-width: 640px;
  }
}

.mobiletitle {
  display: none;
}

@media only screen and (max-width: 1180px) {
  .fundraisercontainer {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 1.5rem;
    max-width: 90vw;
  }

  .fundraisercontainer .fundraiserdescription {
    max-width: 100%;
    order: 2;
  }

  .fundraisercontainer .fundraiserdescription > h1 {
    display: none;
  }

  .fundraisercontainer .fundraiserdata {
    max-width: 90vw;
  }

  .mobiletitle {
    display: block;
    font-size: 1.5rem;
    padding-top: 3rem;
    margin: 0 auto;
    width: 90vw;
    margin-bottom: 0;
    line-height: 150%;
  }
}
