.container {
  width: 100%;
  aspect-ratio: 16/11;
  position: relative;
  overflow: hidden;
}

.graph {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
  animation: shimmer 0.5s infinite linear;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .container {
    aspect-ratio: 16/11;
  }
}

@media screen and (max-width: 1180px) {
  .container {
    aspect-ratio: 1/1;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(200%);
  }
}
