.container {
  margin-top: 1rem;
  width: 100%;
}

.smartdistributionlabel {
  margin: 0.25rem 0 0.25rem 0;
  display: inline-flex;
}

.impact {
  position: relative;
  width: 100%;
  padding-bottom: 0.75rem !important;
}

.smartdistributionlabel,
.impact {
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;

  span {
    display: inline-block;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid var(--primary);
  }
}

.wrapper {
  width: 100%;

  tr {
    width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .container .wrapper tbody tr td {
    display: flex;
    justify-content: flex-start;
  }
}
