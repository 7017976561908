.wrapper {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 2rem;
  width: 100%;
}

.testimonialtrack {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  position: relative;
}

.testimonialtrackinner {
  display: grid;
  grid-auto-columns: 100%;
  width: 100%;
  grid-auto-flow: column;
  white-space: nowrap;
  transition: transform 380ms ease-in-out;
}

.testimonial {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  position: relative;
  justify-content: flex-end;
  white-space: normal;
}

.testemonial__quote {
  max-width: 1036px;
  margin: 70px 0;
  text-align: center;
  white-space: normal;
}

.testimonial__bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  margin-bottom: 80px;
}

.testimonial__bio p {
  margin: 0.1em 0;
  line-height: 1.4;
  text-align: center;
}

.tesimonial__background {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tesimonial__background a {
  border-bottom: 1px solid black;
}

.tesimonial__background a:focus {
  text-decoration: underline;
}

.testemonial__image {
  background-color: var(--primary);
  border-radius: 50%;
  overflow: hidden;
  width: 170px;
  height: 170px;
  position: relative;
  margin-bottom: 2em;
}

.testimonial__arrow {
  font-size: 56px;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  outline: none;
  transition: all 200ms;
}

.testimonial__arrow__hidden {
  opacity: 0.3;
}

.testimonial__arrow__backward {
  left: 0;
}

.testimonial__arrow__forward {
  right: 0;
}

.testimonial__arrow__backward:focus > div,
.testimonial__arrow__forward:focus > div {
  outline: 2px solid var(--primary);
}

.testimonial__arrow__backward:active > div,
.testimonial__arrow__forward:active > div {
  outline: none;
}

.testimonial__dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 10;
}

.testimonial__dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--secondary);
  border: 1px solid var(--primary);
  margin: 0 0.5rem;
  cursor: pointer;
  transition: all 200ms;
}

.testimonial__dot__active {
  background-color: var(--primary);
}

@media screen and (max-width: 1520px) and (min-width: 1181px) {
  .testemonial__quote {
    max-width: 860px;
  }
}

@media screen and (max-width: 1180px) {
  .testimonial {
    padding-bottom: 60px;
  }

  .testimonialtrack {
    width: calc(100vw - 10vw);
  }

  .testemonial__quote {
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 50px;
    text-align: left;
  }

  .tesimonial__background {
    font-size: 0.8rem;
    margin-top: 0.5rem;

    p {
      text-align: left;
      margin-left: 0.5rem;
    }
  }

  .testemonial__image {
    width: 120px;
    height: 120px;
  }

  .testimonial__arrow {
    top: auto;
    font-size: 30px;
    bottom: 0px;
    height: 56px;
  }

  .testimonial__bio {
    font-size: 16px;
    margin-bottom: 0;
  }
}
