.teaser {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
  align-items: center;
  width: 100%;
  padding: 1rem 5rem;
}

.teaser.inverted {
  background: var(--primary);
  color: var(--secondary);
  position: relative;
}

.teaser.inverted::before {
  background: var(--primary);
  width: 100%;
  height: 5rem;
  position: absolute;
  content: "";
  top: -5rem;
  left: 0;
}

.teaser.inverted::after {
  background: var(--primary);
  width: 100%;
  height: 5rem;
  position: absolute;
  content: "";
  bottom: -5rem;
  left: 0;
}

.teasertext {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.teasertext .teaserparagraph {
  margin-top: 60px;
  margin-bottom: 1rem;
  white-space: pre-wrap;
}

.teasertext button {
  margin-left: 0;
}

.teasertext .teaserdisclaimer {
  margin-top: 20px;
  font-size: 16px;
}
.teaser {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
}

.teaserimage {
  background: gray;
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 5;
  z-index: 10;
  justify-self: flex-end;
  line-height: 0;
}

.teaser.inverted .teaserimage {
  justify-self: flex-start;
}

.teasertext {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}

.teasertext .teaserparagraph {
  margin-top: 60px;
  white-space: pre-wrap;
}

.teasertext button {
  margin-left: 0;
}

.teasertext .teaserdisclaimer {
  margin-top: 2rem;
  font-size: 1.2rem;
}

.teaser.inverted .teaserlinks {
  filter: invert(1);
}

.teasertext a {
  border-bottom: 1px solid var(--primary);
}

.teasertext a:focus {
  outline: 1px solid var(--primary);
}

.teaser.inverted .teasertext a {
  border-bottom: 1px solid var(--secondary);
}

.teaser.inverted .teasertext a:focus {
  outline: 1px solid var(--secondary);
}

.teasertext a:active {
  outline: none;
}

.teasertext ul li,
.teasertext ol li {
  margin-bottom: 0.8rem;
}

@media only screen and (max-width: 1180px) {
  .teaser {
    height: auto;
    min-height: auto;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: max-content max-content;
    width: 100%;
    padding: 0;
  }

  .teaser.inverted::before {
    height: 100%;
    width: 5vw;
    left: -5vw;
    top: 0;
  }

  .teaser.inverted::after {
    height: 100%;
    width: 5vw;
    right: -5vw;
    left: auto;
    bottom: 0;
  }

  .teaser:last-child {
    margin-bottom: 0;
  }

  .teaserimage {
    grid-row: 1;
    transform: translateY(-2rem);
    aspect-ratio: 1/1;
  }

  .teasertext {
    padding: 20px 0px;
    max-width: auto;
    grid-row: 2;
    margin-bottom: 1rem;
  }

  .teasertext h3 {
    margin-top: 0;
  }

  .teasertext .teaserparagraph {
    margin-top: 0;
  }
}
