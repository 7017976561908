.inverted {
  --primary: #fafafa;
  --secondary: #000;
  background-color: var(--secondary);
  color: var(--primary);
}

.section__container {
  width: 100%;
  padding: 0 5vw;
}

.section__container .section__container {
  padding: 0;
}

.divider {
  border-top: solid var(--primary) 1px;

  margin: 0 auto;
  max-width: 1920px;
}

.section__heading {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;

  &:not(:empty) {
    padding-top: 28px;
  }
}

.section__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1920px;

  &.ypadded {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.padded {
  padding-left: 5vw;
  padding-right: 5vw;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .section__heading:not(:empty) {
    padding-top: 18px;
  }
}

@media only screen and (max-width: 1180px) {
  .section__heading {
    /* Small type */
    font-size: 14px;

    &:not(:empty) {
      padding-top: 4px;
    }
  }

  .section__container {
    margin-bottom: 0;
  }

  .section__content.ypadded {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .padded {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1920px) {
  .section__content,
  .section__heading,
  .divider {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .section__content.padded {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
