.grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
  margin: 50px 0;
}

.column {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 90%;
  align-items: flex-start;
}

.column button {
  margin-left: 0;
}

@media screen and (max-width: 1180px) {
  .grid {
    grid-auto-columns: none;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
  }

  .column {
    padding: 0;
  }
}
